import React from "react";
import classes from "./Button.module.scss";
import { Link, useParams } from "react-router-dom";


const Button = ({ setFormOpened, children, login, toVacancyPage = false }) => {
  const { lang } = useParams();

  if (toVacancyPage && login) {
    return (
      <Link className={`${classes.button1} font-18`} to={`/${lang}/careers`}>
        {children}
      </Link>
    )
  }
  if (toVacancyPage) {
    return (
      <Link className={`${classes.button} font-18`} to={`/${lang}/careers`}>
        {children}
      </Link>
    )
  }
  return (
    <>
      {login ? (
        <div onClick={() => setFormOpened(true)} className={`${classes.button1} font-18`}>
          <span>{children}</span>
        </div>
      ) : (
        <div onClick={() => setFormOpened(true)} className={`${classes.button} font-18`}>
          <span>{children}</span>
        </div>
      )}
    </>
  );
};

export default Button;
