import React from "react";
import classes from "./Retention.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import { useTranslation, Trans } from "react-i18next";

const blocks = [
    {
        subtitle: 'vacancy.retention.block_02.subtitle',
        list: [
            'vacancy.retention.block_02.li_01',
            'vacancy.retention.block_02.li_02',
            'vacancy.retention.block_02.li_03',
            'vacancy.retention.block_02.li_04',
            'vacancy.retention.block_02.li_05',
            'vacancy.retention.block_02.li_06',
        ],
    },
    {
        subtitle: 'vacancy.retention.block_03.subtitle',
        list: [
            'vacancy.retention.block_03.li_01',
            'vacancy.retention.block_03.li_02',
            'vacancy.retention.block_03.li_03',
            'vacancy.retention.block_03.li_04',
            'vacancy.retention.block_03.li_05',
            'vacancy.retention.block_03.li_06',
        ],
    },
];

const Retention = ({ setFormOpened }) => {
    const { t } = useTranslation();
    return (
        <div className={`${classes.sub} mb`}>
            <div className={classes.subBody}>
                <div className="container">
                    <div className={classes.subRow}>
                        <div className={classes.subRowLeftContent}>
                            <h2 className={`${classes.subRowLeftContentTitle} font-64`}>
                                <Trans>vacancy.retention.title</Trans>
                            </h2>
                            <p className={`${classes.subRowLeftContentSubTitle} font-20-b`}>
                                <Trans>vacancy.retention.text</Trans>
                            </p>
                            <div className={classes.subRowLeftContentBtn}>
                                <Button setFormOpened={setFormOpened} login={"true"}><Trans>apply_btn</Trans></Button>
                            </div>
                        </div>
                        <div className={classes.subRowRightColumn}>
                            <h3 className={`${classes.subRowRightColumnTitle} font-24`}>
                                {t('vacancy.retention.block_01.subtitle')}
                            </h3>
                            <ul className={`${classes.subRowRightColumnList} font-16`}>
                                <li className={classes.subRowRightColumnListItem}>
                                    {t('vacancy.retention.block_01.li_01')}
                                </li>
                                <li className={classes.subRowRightColumnListItem}>
                                    {t('vacancy.retention.block_01.li_02')}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`${classes.subRow} ${classes.subRow2}`}>
                        {blocks.map((block, index) =>
                            <div key={index} className={classes.subRowRightColumn}>
                                <h3 className={`${classes.subRowRightColumnTitle} font-24`}>
                                    {t(block.subtitle)}
                                </h3>
                                <ul className={`${classes.subRowRightColumnList} font-16`}>
                                    {block.list.map((li, liIndex) =>
                                        <li key={liIndex} className={classes.subRowRightColumnListItem}>
                                            {t(li)}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className={classes.desc}>
                        {t('vacancy.retention.desc')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Retention;
