import React, { useState } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/HomePage/Banner/Banner";
import OurValues from "../../components/HomePage/OurValues/OurValues";
import Management from "../../components/HomePage/Management/Management";
import Explore from "../../components/HomePage/Explore/Explore";
import Form from "../../components/Form/Form";
import SecondOurValues from "../../components/HomePage/SecondOurValues/SecondOurValues";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Faq from "../../components/HomePage/Faq/Faq";
import WhyDiffer from "../../components/HomePage/WhyDiffer/WhyDiffer";

const HomePage = () => {
    const [formOpened, setFormOpened] = useState(false);
    return (
        <div className="ovf-hidden">
            <Header formOpened={formOpened} setFormOpened={setFormOpened} />
            <Banner />
            <OurValues />
            <Management />
            <WhyDiffer />
            <Explore />
            <SecondOurValues />
            <Faq />
            <Footer />
            <Form formOpened={formOpened} setFormOpened={setFormOpened} />
        </div>
    );
};

export default HomePage;
