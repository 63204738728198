import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer"
import Video from "../../components/AboutPage/Video/Video";

const AboutPage = () => {
  const [formOpened, setFormOpened] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <div className="ovf-hidden">
        <Header formOpened={formOpened} setFormOpened={setFormOpened} />
        <Video />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
