import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo_b.svg";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { hostname } = document.location;
  const { t } = useTranslation();
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={classes.info}>
          <div className={classes.contacts}>
            <div className={classes.contactsFirst}>
              <p>{t('footer_email')}</p>
              <a href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
            </div>
            <div className={classes.contactsSecond}>
              <p>{t('footer_phones')}</p>
              <a href="tel:+48574671223">+48 574 671 223</a>
              <a href="tel:+48574420772">+48 574 420 772</a>
            </div>
            <div className={classes.contactsSecond}>
              <p>{t('footer_address')}</p>
              <p>al. Jana Pawła II 2200-001 Warszawa, Poland</p>
            </div>
          </div>
          <div className={classes.docs}>
            <a href="/docs/TERMS_AND_CONDITIONS.pdf">
              <Trans>footer_terms_conditions</Trans>
            </a>
            <a href="/docs/PRIVACY_POLICY.pdf">
              <Trans>footer_privacy_policy</Trans>
            </a>
          </div>
        </div>
        <div className={classes.disclaimer}>
          <p className={classes.disclaimerTitle}>
            <Trans>footer_title_desclaimer</Trans>
          </p>
          <p>
            <Trans>footer_desclaimer</Trans>
          </p>
          <p className={classes.rights}>
            {t('footer_copyright')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
