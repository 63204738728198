import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/main/1/1.webp';
import img2 from '../assets/img/main/1/2.webp';
import img3 from '../assets/img/HomePage/Banner/bg.webp';
import img4 from '../assets/img/HomePage/Banner/bg_mob.webp';
import img5 from '../assets/img/HomePage/Banner/object.webp';
import img6 from '../assets/img/HomePage/Banner/sphere.webp';

export const images = [
    logo,
    img1, img2, img3, img4, img5, img6,
];

