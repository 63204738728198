import React, { useEffect, useRef } from "react";
import classes from './OurValues.module.scss';
import image_01 from '../../../assets/img/HomePage/OurValues/image_01.webp';
import image_02 from '../../../assets/img/HomePage/OurValues/image_02.webp';
import image_03 from '../../../assets/img/HomePage/OurValues/image_03.webp';
import image_04 from '../../../assets/img/HomePage/OurValues/image_04.webp';
import sphere from '../../../assets/img/HomePage/OurValues/sphere.webp';
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const OurValues = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const cardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const sphereRef_01 = useRef(null);
    const sphereRef_02 = useRef(null);
    const sphereRef_03 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            image: image_01,
            text: 'main_2_2',
            subtitle: 'main_2_2.subtitle',
        },
        {
            ref: cardRef_02,
            image: image_02,
            text: 'main_2_3',
            subtitle: 'main_2_3.subtitle',
        },
        {
            ref: cardRef_03,
            image: image_03,
            text: 'main_2_4',
            subtitle: 'main_2_4.subtitle',
        },
        {
            ref: cardRef_04,
            image: image_04,
            text: 'main_2_5',
            subtitle: 'main_2_5.subtitle',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: -100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 60,
                x: 20,
            }, {
                y: 0,
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 60,
                x: -20,
            }, {
                y: 0,
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                x: 100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            // gsap.timeline().fromTo(cardRef_04.current, {
            //     x: 100,
            // }, {
            //     x: 0,
            //     scrollTrigger: {
            //         trigger: cardsRef.current,
            //         end: "bottom bottom",
            //         scrub: 3,
            //     }
            // });
        } else {
            gsap.fromTo(cardRef_01.current, {
                x: -100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_01.current,
                    start: "bottom bottom",
                }
            });
            gsap.fromTo(cardRef_02.current, {
                x: 100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_02.current,
                    start: "bottom bottom",
                }
            });
            gsap.fromTo(cardRef_03.current, {
                x: -100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_03.current,
                    start: "bottom bottom",
                }
            });
            gsap.fromTo(cardRef_04.current, {
                x: 100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_04.current,
                    start: "bottom bottom",
                }
            });
        }
    }, []);
    return (
        <section className="container mt">
            <div ref={bodyRef} className={classes.ourValues}>
                <img ref={sphereRef_01} className={[classes.sphere, classes.sphere_01].join(' ')} src={sphere} alt='' />
                <img ref={sphereRef_02} className={[classes.sphere, classes.sphere_02].join(' ')} src={sphere} alt='' />
                <img ref={sphereRef_03} className={[classes.sphere, classes.sphere_03].join(' ')} src={sphere} alt='' />
                <h2 className={[classes.title, 'font-64-b'].join(' ')}>
                    <Trans>main_2_1</Trans>
                </h2>
                <div ref={cardsRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img className={classes.cardImage} src={card.image} alt='' />
                            <h4 className={`${classes.subtitle} font-16-b`}>
                                {t(card.subtitle)}
                            </h4>
                            <p className={[classes.cardText, 'font-16-b'].join(' ')}>
                                <Trans>{card.text}</Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurValues;
