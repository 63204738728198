import React from "react";
import classes from './Video.module.scss';
import ReactPlayer from "react-player";
import video from '../../../assets/img/AboutPage/about.MP4';

const Video = () => {
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.player}>
                    <ReactPlayer
                        url={video}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        </section>
    );
};

export default Video;
