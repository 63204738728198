import React from "react";
import classes from './Steps.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/main/Steps/icon_01.svg';
import icon_02 from '../../../assets/img/main/Steps/icon_02.svg';
import icon_03 from '../../../assets/img/main/Steps/icon_03.svg';
import Button from "../../GlobalComponents/Button/Button";

const cards = [
    {
        icon: icon_01,
        subtitle: 'vacancy.steps.card_01.subtitle',
        text: 'vacancy.steps.card_01.text',
    },
    {
        icon: icon_02,
        subtitle: 'vacancy.steps.card_02.subtitle',
        text: 'vacancy.steps.card_02.text',
    },
    {
        icon: icon_03,
        subtitle: 'vacancy.steps.card_03.subtitle',
        text: 'vacancy.steps.card_03.text',
    },
];

const Steps = ({ setFormOpened }) => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-48`}>
                    {t('vacancy.steps.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.icon} src={card.icon} alt='' />
                            <h4 className={`${classes.subtitle} font-20`}>
                                {t(card.subtitle)}
                            </h4>
                            <p className={`${classes.text} font-16-b`}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
                <p className={`${classes.desc} font-16-b`}>
                    {t('vacancy.steps.desc')}
                </p>
                <div className={classes.btn}>
                    <Button setFormOpened={setFormOpened} login={"true"}>
                        {t('apply_btn')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Steps;
