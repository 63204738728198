import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/VacancyPage/Banner/Banner";
import InvConsultant from "../../components/VacancyPage/InvConsultant/InvConsultant";
import Footer from "../../components/GlobalComponents/Footer/Footer"
import Form from "../../components/Form/Form";
import SalesManager from "../../components/VacancyPage/SalesManager/SalesManager";
import Analysis from "../../components/VacancyPage/Analysis/Analysis";
import Retention from "../../components/VacancyPage/Retention/Retention";
import Steps from "../../components/VacancyPage/Steps/Steps";

const HomePage = () => {
  const [formOpened, setFormOpened] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <div className="ovf-hidden">
        <Header formOpened={formOpened} setFormOpened={setFormOpened} />
        <Banner formOpened={formOpened} setFormOpened={setFormOpened} />
        <InvConsultant formOpened={formOpened} setFormOpened={setFormOpened} />
        <SalesManager setFormOpened={setFormOpened} />
        <Analysis setFormOpened={setFormOpened} />
        <Retention setFormOpened={setFormOpened} />
        <Steps setFormOpened={setFormOpened} />
        <Footer />
        <Form formOpened={formOpened} setFormOpened={setFormOpened} />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
