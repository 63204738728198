import HomePage from '../pages/HomePage/HomePage';
import VacancyPage from '../pages/VacancyPage/VacancyPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import ThankPage from '../pages/ThankPage/ThankPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/careers', element: VacancyPage },
    { path: '/about-us', element: AboutPage },
    { path: '/thankyou', element: ThankPage },
    { path: '/:lang', element: HomePage },
    { path: '/:lang/careers', element: VacancyPage },
    { path: '/:lang/about-us', element: AboutPage },
    { path: '/:lang/thankyou', element: ThankPage },
    { path: '*', element: HomePage },
];

export const headerRoutes = [
];
