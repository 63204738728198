import gsap from "gsap";
import { useEffect, useRef } from "react";
import classes from "./Explore.module.scss"
import img1 from "../../../assets/img/HomePage/5/1.webp"
import { useTranslation, Trans } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';

const Explore = () => {
    const { t } = useTranslation();
    const swiperWrapRef = useRef(null);
    const ref1 = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            gsap.timeline().to(swiperWrapRef.current, {
                scrollTrigger: {
                    trigger: ref1.current,
                    end: "bottom bottom",
                    scrub: 3,
                },
                y: 0,
                autoAlpha: 1,
            });
        }, 300);

    }, []);

    return (
        <div className={[classes.explore, "mb"].join(" ")} ref={ref1}  >
            <div className={[classes.exploreBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.exploreRow, ""].join(" ")}>
                        <div className={[classes.exploreRowRight, ""].join(" ")}>
                            <h2 className={[classes.exploreRowRightTitle, "font-64"].join(" ")}>
                                <Trans>main_5_1</Trans>
                            </h2>
                            <p className={[classes.exploreRowRightText, "font-16"].join(" ")}>
                                <Trans>main_5_2</Trans>
                            </p>
                            <p className={[classes.exploreRowRightText, "font-16"].join(" ")}>
                                <Trans>main_5_3</Trans>
                            </p>
                            <p className={[classes.exploreRowRightText, "font-16"].join(" ")}>
                                <Trans>main_5_4</Trans>
                            </p>
                            <div className={classes.btn}>
                                <Button toVacancyPage login={"true"}>
                                    {t('apply_btn')}
                                </Button>
                            </div>
                        </div>
                        <div className={[classes.exploreRowLeft, ""].join(" ")}>
                            <div className={[classes.exploreRowLeftImg, ""].join(" ")} ref={swiperWrapRef}>
                                <img src={img1} alt="Explore Career Opportunities" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Explore