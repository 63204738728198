import { useEffect, useRef } from "react";
import classes from "./Analysis.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import { useTranslation, Trans } from "react-i18next";
import img1 from "../../../assets/img/main/3/1.webp"
import img2 from "../../../assets/img/main/3/2.webp"
import img4 from "../../../assets/img/main/3/plan.webp"
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const blocks = [
    {
        subtitle: 'vacancy.analysis.block_01.subtitle',
        list: [
            'vacancy.analysis.block_01.li_01',
            'vacancy.analysis.block_01.li_02',
            'vacancy.analysis.block_01.li_03',
            'vacancy.analysis.block_01.li_04',
            'vacancy.analysis.block_01.li_05',
            'vacancy.analysis.block_01.li_06',
        ],
    },
    {
        subtitle: 'vacancy.analysis.block_02.subtitle',
        list: [
            'vacancy.analysis.block_02.li_01',
            'vacancy.analysis.block_02.li_02',
            'vacancy.analysis.block_02.li_03',
            'vacancy.analysis.block_02.li_04',
            'vacancy.analysis.block_02.li_05',
        ],
    },
    {
        subtitle: 'vacancy.analysis.block_03.subtitle',
        list: [
            'vacancy.analysis.block_03.li_01',
            'vacancy.analysis.block_03.li_02',
        ],
    },
];

const Analysis = ({ setFormOpened }) => {
    const { t } = useTranslation();
    const col1 = useRef(null);
    const monetRef1 = useRef(null);
    const monetRef2 = useRef(null);
    const monetRefMob = useRef(null);

    useEffect(() => {
        gsap.timeline().to(monetRefMob.current, {
            scrollTrigger: {
                trigger: col1.current,
                start: "top center",
                end: "top top",
                scrub: 10,
            },
            scale: 1,
            x: 0
        });
        gsap.timeline().to(monetRef1.current, {
            scrollTrigger: {
                trigger: col1.current,
                start: "top center",
                end: "top top",
                scrub: 10,
            },
            scale: 1,
            x: 0
        });
        gsap.timeline().to(monetRef2.current, {
            scrollTrigger: {
                trigger: col1.current,
                start: "top center",
                end: "top top",
                scrub: 10,
            },
            x: 0,
            scale: 1
        });
    }, []);

    return (
        <div className={`${classes.sub} mb`} ref={col1}>
            <div className={classes.subBody}>
                <div className={classes.subImg1} ref={monetRef1}>
                    <img src={img1} alt="" />
                </div>
                <div className={classes.subImg2}>
                    <img ref={monetRef2} src={img2} alt="" />
                </div>
                <div className="container">
                    <div className={classes.subRow}>
                        <div className={classes.subRowLeft}>
                            <div className={classes.subRowLeftContent}>
                                <h2 className={`${classes.subRowLeftContentTitle} font-64`}>
                                    <Trans>vacancy.analysis.title</Trans>
                                </h2>
                                <p className={`${classes.subRowLeftContentSubTitle} font-20-b`}>
                                    <Trans>vacancy.analysis.text</Trans>
                                </p>
                                <div className={classes.subRowLeftContentBtn}>
                                    <Button setFormOpened={setFormOpened} login={"true"}><Trans>apply_btn</Trans></Button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.subRowRight}>
                            {blocks.map((block, index) =>
                                <div key={index} className={classes.subRowRightColumn}>
                                    <h3 className={`${classes.subRowRightColumnTitle} font-24`}>
                                        {t(block.subtitle)}
                                    </h3>
                                    <ul className={`${classes.subRowRightColumnList} font-16`}>
                                        {block.list.map((li, liIndex) =>
                                            <li key={liIndex} className={classes.subRowRightColumnListItem}>
                                                {t(li)}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.subPlanImg} ref={monetRefMob}>
                    <img src={img4} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Analysis;
