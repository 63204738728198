import React, { useEffect } from "react";
import classes from './Banner.module.scss';
import object from '../../../assets/img/HomePage/Banner/object.webp';
import sphere from '../../../assets/img/HomePage/Banner/sphere.webp';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
import { useTranslation, Trans } from "react-i18next";
import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    useEffect(() => {
        gsap.fromTo('#homeBannerObject', {
            rotate: -10,
        }, {
            x: 0,
            y: 0,
            scale: 1,
            rotate: 0,
            duration: 3,
        });
        gsap.to('#homeBSp_01', {
            x: 0,
            y: 0,
            rotate: 48,
            duration: 3,
        });
        gsap.to('#homeBSp_02', {
            x: 0,
            y: 0,
            duration: 3,
        });
        gsap.to('#homeBSp_03', {
            x: 0,
            y: 0,
            rotate: 0,
            duration: 3,
        });
        gsap.to('#homeBSp_04', {
            x: 0,
            y: 10,
            duration: 3,
        });
        gsap.to('#homeBSp_05', {
            x: 0,
            y: 0,
            rotate: 93,
            duration: 3,
        });
        gsap.to('#homeBSp_06', {
            x: 0,
            y: 0,
            rotate: 72,
            duration: 3,
        });
        if (document.documentElement.clientWidth > 1220) {
        }
        const scrTrigger = {
            trigger: '#homeBannerBody',
            start: "+=10%",
            end: "+=600px",
            scrub: 3,
        };
        gsap.timeline().to('#homeBannerObjectWrap', {
            y: -60,
            scale: 0.8,
            rotate: -10,
            scrollTrigger: scrTrigger,
        });
        gsap.timeline().to('#homeBSpCont_01', {
            y: -100,
            scrollTrigger: scrTrigger,
        });
        gsap.timeline().to('#homeBSpCont_02', {
            y: -30,
            scrollTrigger: scrTrigger,
        });
        gsap.timeline().to('#homeBSpCont_03', {
            y: -120,
            scrollTrigger: scrTrigger,
        });
        gsap.timeline().to('#homeBSpCont_04', {
            y: -50,
            scrollTrigger: scrTrigger,
        });
        gsap.timeline().to('#homeBSpCont_05', {
            y: -100,
            scrollTrigger: scrTrigger,
        });
        gsap.timeline().to('#homeBSpCont_06', {
            y: -50,
            scrollTrigger: scrTrigger,
        });
    }, []);
    return (
        <section id="homeBannerBody" className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={classes.title}>
                            Witold Zawadka
                        </h1>
                        <h2 className={classes.subtitle}>
                            {t('main_1_2')}
                        </h2>
                        <p className={classes.text}>
                            <Trans>main_1_3</Trans>
                        </p>
                        <div className={classes.btn}>
                            <Button toVacancyPage>
                                <Trans>apply_btn</Trans>
                            </Button>
                        </div>
                    </div>
                    <div className={classes.images}>
                        <div id="homeBannerObjectWrap" className={classes.objectWrap}>
                            <img id="homeBannerObject" className={classes.object} src={object} alt='' />
                        </div>
                        <div className={[classes.sphereWrap, classes.sphereWrap_01].join(' ')}>
                            <div id="homeBSpCont_01" className={classes.sphereContainer}>
                                <img id="homeBSp_01" className={classes.sphere} src={sphere} alt='' />
                            </div>
                        </div>
                        <div className={[classes.sphereWrap, classes.sphereWrap_02].join(' ')}>
                            <div id="homeBSpCont_02" className={classes.sphereContainer}>
                                <img id="homeBSp_02" className={classes.sphere} src={sphere} alt='' />
                            </div>
                        </div>
                        <div className={[classes.sphereWrap, classes.sphereWrap_03].join(' ')}>
                            <div id="homeBSpCont_03" className={classes.sphereContainer}>
                                <img id="homeBSp_03" className={classes.sphere} src={sphere} alt='' />
                            </div>
                        </div>
                        <div className={[classes.sphereWrap, classes.sphereWrap_04].join(' ')}>
                            <div id="homeBSpCont_04" className={classes.sphereContainer}>
                                <img id="homeBSp_04" className={classes.sphere} src={sphere} alt='' />
                            </div>
                        </div>
                        <div className={[classes.sphereWrap, classes.sphereWrap_05].join(' ')}>
                            <div id="homeBSpCont_05" className={classes.sphereContainer}>
                                <img id="homeBSp_05" className={classes.sphere} src={sphere} alt='' />
                            </div>
                        </div>
                        <div className={[classes.sphereWrap, classes.sphereWrap_06].join(' ')}>
                            <div id="homeBSpCont_06" className={classes.sphereContainer}>
                                <img id="homeBSp_06" className={classes.sphere} src={sphere} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
