import React, { useState } from "react";
import classes from './WhyDiffer.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        subtitle: 'home.why_differ.tab_01.subtitle',
        desc: 'home.why_differ.tab_01.desc',
    },
    {
        subtitle: 'home.why_differ.tab_02.subtitle',
        desc: 'home.why_differ.tab_02.desc',
    },
    {
        subtitle: 'home.why_differ.tab_03.subtitle',
        desc: 'home.why_differ.tab_03.desc',
    },
];

const WhyDiffer = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    const setTab = (value) => value === active
        ? setActive(-1)
        : setActive(value);
    return (
        <section className="mb container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-64-b`}>
                        {t('home.why_differ.title')}
                    </h2>
                    <h3 className={`${classes.subtitle} font-30`}>
                        {t('home.why_differ.subtitle')}
                    </h3>
                </div>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            onClick={() => setTab(index)}
                            className={`${classes.tab} ${active === index ? classes.active : ''}`}
                        >
                            <div className={classes.head}>
                                <h4 className={`${classes.subtitle} font-20`}>
                                    {t(tab.subtitle)}
                                </h4>
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <rect x="0.375" y="0.375" width="29.25" height="29.25" rx="14.625" stroke="#245476" strokeWidth="0.75" />
                                    <path d="M12.75 21L18.75 15.2045L12.75 9.75" stroke="#245476" strokeWidth="0.75" />
                                </svg>
                            </div>
                            <p className={classes.desc}>
                                {t(tab.desc)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyDiffer;
